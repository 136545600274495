.creativeai{
  .account-name-creativeai{
    opacity: 0.5;
    font: 1em sans-serif;
  } 
  .account-id-creativeai{
    opacity: 0.5;
    font: 0.8em sans-serif;
  }
}
.date-range-picker {
    padding-top: 1rem;
    position: relative;
  }
.buttons-container {
display: flex;
justify-content: space-between;
.date-range-picker {
    padding-top: 1rem;
    position: relative;
}
.button-container {
    display: flex;
    .btn-relative {
    position: relative;
    margin-top: 1rem;
    padding: 0;
    }
}
}
.activitylog-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .table-logs{
    min-height: 500px;
    .table-item,.th-header-item,.td-data-item{
      border: 1px solid lightgray;
    }
    .th-header-item{
      div{
        font-size: small;
        font-weight: bolder;
        span{
          font-size: x-small;
        }
      }
      div::-webkit-scrollbar {
        display: none;
      }
    }
    .td-data-item{
      div{
        display: flex;
        align-items: center;
        max-width: 120px;
        overflow-x:scroll;
        font-size: small;
        font-weight: lighter;
      }
      div::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .pagination-logs{
    background-color: #fff;
    width: 103%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom:-12px;
    // left: 0%;
  
    .page-button{
      border-style: solid;
      background-color: #fff;
      border-color: #BCBCBC;
      border-radius: 20%;
      width: 30px;
      height: 30px;
      color: #BCBCBC;
      margin: 0.5%;
    }
    .page-text-button{
      all: unset;
      color: #BCBCBC;
      padding: 1.5%;
      cursor: pointer;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 800;
      font-size: small;
      line-height: 13px;
    }
    .disabled{
      color: #E5E5E9;
    }
    .active{
      background-color: #0869FB;
      color: #fff;
      border-style: none;

    }
  }
  table{
    overflow: scroll;
  }
}

.performance-calendar-creativeai {
    position: absolute;
    z-index: 1031;
    right: 10px;
    top: 70px;
    padding: 10px;
    .rdrStaticRange:nth-last-of-type(1) {
      display: none;
    }
    .rdrInputRange:nth-last-of-type(1) {
      display: none;
    }
    .rdrInputRange:nth-last-of-type(2) {
      display: none;
    }
  }

  .icon-position{
      position: relative;
      top:-15px;
      left:-133px;
      .soon-position{
        position: absolute;
        left:5px;
        top:1px;
        font-size: 10px;
        color: #fff;
        font-style: normal;
    }
  }
  