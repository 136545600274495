.user-details {
  .user-details-form {
    .tooltip-container {
      display: inline;
      position: relative;
    }
    .form-title {
      font-weight: 700;
    }
    .form-group {
      label {
        font-size: 14px;
        line-height: 16px;
      }
      .form-control {
        border-radius: 8px;
        font-size: 14px;
        line-height: 16px;
        padding: 0.7rem 0.7rem;
      }
      .form-select {
        background-image: url("data:image/svg+xml,<svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.02243 7.27467L11.7006 2.57709C11.8592 2.41737 11.9596 2.22384 11.9901 2.01899C12.0206 1.81413 11.98 1.60634 11.873 1.41975C11.7659 1.23316 11.5968 1.07542 11.3853 0.964841C11.1739 0.854263 10.9286 0.795386 10.6782 0.795044H1.32182C1.07136 0.795386 0.826149 0.854263 0.614664 0.964841C0.403179 1.07542 0.234094 1.23316 0.12704 1.41975C0.0199848 1.60634 -0.0206484 1.81413 0.00985885 2.01899C0.0403661 2.22384 0.140762 2.41737 0.299389 2.57709L4.97757 7.27467C5.10178 7.39908 5.25788 7.49927 5.43466 7.56806C5.61143 7.63685 5.8045 7.67252 6 7.67252C6.1955 7.67252 6.38857 7.63685 6.56535 7.56806C6.74213 7.49927 6.89822 7.39908 7.02243 7.27467Z' fill='black' /></svg>");
        background-size: 12px;
        padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      }
      .radio {
        label {
          margin-right: 3rem;
        }
        .radio-input {
          margin-right: 0.5rem;
          vertical-align: middle;
        }
      }
      .form-control:focus {
        border-color: #dddddd;
        box-shadow: none;
      }
    }
    .flex-100 {
      flex: 100%;
    }
    .flex-50 {
      flex: 50%;
      padding-right: 10rem;
    }
  }
  .footer-user-details {
    margin-top: auto;
    .terms {
      font-size: 12px;
      border-bottom: 1px solid rgb(30 21 83 / 20%);
    }
  }
  .footer-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // box-shadow: 0px 0px 2px rgba(30, 21, 83, 0.2);
    width: 100%;
  }
  .width-40 {
    width: 40%;
  }
  .link-click {
    color: #0869fb !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
}
