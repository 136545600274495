.outline-screen {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .setup-heading {
    display: flex;
    padding: 10px;
    align-items: center;
    .heading-icon {
      width: 30px;
      height: 30px;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px;
    }
  }

  .outline-heading {
    width: 100%;
    height: 5%;
    border-bottom: 1px solid #e2dddd;
    padding: 20px;
    font-weight: bold;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
  .setup {
    width: 95%;
    height: 100%;
    margin: 20px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0.5px 0.5px 4px #8a8a8a;
  }
  .montage-setup {
    border-bottom: 1px solid #e2dddd;
    width: 100%;
  }
  .montage-details {
    display: flex;
    width: 100%;

      .montageDetails {
        margin-right: 100px;
        margin-bottom: 20px;
        width: 30%;

        label {
          width: 200px;
          display: flex;
          font-weight: 500;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
            .montage-tooltip {
              width: 150px;
              margin: 0px;
            }
        }
      }
  }
  .product-details {
    width: 100%;
  }
  .product-info {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .productCategoryDetails {
      width: 30%;
      margin-right: 100px;
      margin-bottom: 20px;
      .form-control {
        width: 90%;
      }
    }
    .productDetails {
      width: 30%;
      margin-right: 100px;
      margin-bottom: 20px;
    }
    .productImage {
      width: 100%;
    }
  }
  .montage-input {
    width: 90%;
  }
  h6 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  label {
    width: 200px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
  }
  .currency-dropdown {
    width: 90%;
    display: flex;
    border: 1px solid #e2dddd;
    padding: 5px;
    border-radius: 4px;

    .form-control {
      padding-right: 5px;
      width: 42%;
    }

    .montage-input1 {
      width: 58%;
    }
  }
  .creative-heading {
    font-weight: 500;
    font-size: small;
    color: #1c1e21;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
  }
  .creative-details {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px;
    padding-top: 0px;

    .target {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px;

      .category {
        width: 40%;
        font-size: small;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
          Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

        .dataList {
          width: 100%;

          .list {
            flex-wrap: wrap;
          }
        }
      }
    }
    .interest {
      width: 100%;
      margin: 10px;
      .col-lg-3 {
        width: 24% !important;
      }
    }
    .creative-layout {
      width: 100%;
      margin: 10px;
      margin-top: 15px;

      label {
        width: 300px;
        display: flex;
        font-weight: 500;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
          Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      }

      .add-new-guideline {
        cursor: pointer;
      }
      small {
        display: flex;
        width: 80%;
        justify-content: space-between;
        color: #1c1e21;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
          Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        div {
          color: #0869fb;
          font-weight: 500;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        }
      }
      .slider {
        width: 60%;
        margin: 10px;
      }
      .layouts {
        display: flex;
        width: 100%;
        margin-top: 10px;
        .singleLayout {
          width: 100px;
          height: 100px;
          background-color: #f2f2f2;
          margin-right: 10px;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .form-control {
            padding-right: 10px;
            width: 42%;
          }

          .montage-input1 {
            width: 58%;
          }
        }
        .creative-heading {
          font-weight: bold;
          font-size: small;
          color: #1c1e21;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        }
        .creative-details {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 10px;

          small {
            font-size: smaller;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
              Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
          }

          .target {
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px;

            .category {
              width: 40%;
              font-size: small;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
                Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

              .dataList {
                width: 100%;

                .list {
                  flex-wrap: wrap;
                }
              }
            }
          }
        }
      }
    }
    .interest {
      width: 100%;
      margin: 10px;
      .col-lg-3 {
        width: 24% !important;
      }
    }
    .creative-layout {
      width: 100%;
      margin: 10px;
      margin-top: 15px;
      .contentGuidelines {
        display: flex;
        width: 80%;
        justify-content: space-between;
        label {
          width: 60%;
        }
        .add-new-guideline {
          cursor: pointer;
          font-size: small;
          color: #0869fb;
          font-weight: 500;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        }
      }

      .guidelines {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        border: 1px solid #e2dddd;
        border-radius: 4px;

        .singleGuideline {
          width: 95%;
          height: 40px;
          margin: 10px;
          border: 1px solid #e2dddd;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: small;
          padding-left: 5px;
          position: relative;

          .sideDots {
            cursor: pointer;
          }

          div {
            width: 90%;
            margin: 10px;
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .sideMenu {
            cursor: pointer;
            width: 80px;
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            position: absolute;
            top: 5%;
            left: 99%;
            box-shadow: 0.5px 0.5px 2px grey;
            border-radius: 4px;

            button {
              width: 100%;
              height: 100%;
              border: none;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: flex-start;
            }

            .sideIcon {
              margin-right: 10px;
              width: 13px;
            }
          }
        }
        .view-more {
          cursor: pointer;
          font-size: small;
          color: #0869fb;
          padding: 10px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
            Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        }
      }
      .style-guide {
        width: 80%;
        margin-top: 15px;
        .styleguide {
          width: 50%;
          border: 1px solid #e2dddd;
          border-radius: 4px;
          font-size: small;
        }
      }
      .style-guide {
        width: 80%;
        .styleguide {
          width: 50%;
          border: 1px solid #e2dddd;
          border-radius: 4px;
          font-size: smaller;
        }
      }
    }
  }
  .outline-footer {
    width: 100%;
    border-top: 1px solid #e2dddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    padding-top: 10px;
    div {
      margin-left: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px;
      padding-top: 10px;
      div {
        margin-left: 20px;
        display: flex;
        align-items: center;
      }
      .back-btn {
        display: flex;
        text-decoration: none;
        color: black;
        align-items: center;
        justify-content: space-between;

        .leftArrow {
          margin-right: 5px;
        }
      }
    }
    .launch_button {
      background-color: #0869fb !important;
      color: white;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .rightArrow {
        margin-left: 5px;
      }
    }
    .back-btn {
      text-decoration: none;
      color: black;
    }

    .modal-guidelines {
      width: 100%;
    }
  }
}
.modal-guidelines {
  width: 100%;
  background-color: white;
}
.modal-singleGuideline {
  width: 90%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e2dddd;
  margin: 10px;
  padding-left: 5px;
  position: relative;

  .sideDots {
    cursor: pointer;
  }

  .viewMoreMenu {
    width: 80px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 45px;
    left: 95%;
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 2px grey;
    z-index: 1;
    button {
      height: 100%;
      border: none;
      border-radius: 10px;
      background-color: white;
      font-size: small;

      .sideIcon {
        margin-right: 10px;
        width: 13px;
      }
    }
  }

  div {
    width: 85%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .data-list-container {
    width: 100%;
  }

}
.modal-add-guidelines {
  .radio-input{
    margin: 5px;
  }
  .guideline-error{
    color:red
  }
}