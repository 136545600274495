.saving-calculator {
  font-family: Roboto;
  padding: 16px;
  background-color: white;
  margin-right: 40px;
  white-space: nowrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width:auto;
  height: 80px;
  border-radius: 10px;

  .heading div {
    color: black;
    font-weight: 700;
    font-size: 18px;
    // margin-bottom: 6.5px;
  }

  .heading .date-range div {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    color: #5f6771;
  }

  .savingpercentage,
  .savingvalue {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
  }

  .savingvalue {
    border-left: 1px solid #cecece;
  }

  .savingpercentage .title,
  .savingvalue .title {
    font-size: 12px;
  }

  .savingpercentage .detail,
  .savingvalue .detail {
    font-size: 16px;
    color: #12c8b2;
    font-weight: 600;
  }

  .moreInfoLink a {
    font-size: 12px;
    text-decoration: none;
  }
}