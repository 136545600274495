.no-navigation {
  padding-bottom: 0.5rem !important;
  padding-top: 0.2rem !important;
}



.navigation--container {
  display: flex;
  user-select: auto;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .navigation-dropdown {
    margin-right: 0px;


    .navigation-dropdown-btn {
      .dropdown-toggle {
        width: 180px;
        height: 36px;

      }
    }

    .dropdown-ui {
      .dropdown-item-container {
        width: 100%;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: unset;

        .dropdown-item {
          font-family: Roboto;
          font-size: 13px !important;
          line-height: 24px;
          color: #000000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 16rem;
        }
      }
    }

    .group-btn {
      width: 9rem;
      width: 180px;
      height: 36px;
      background: #e9e9e9 !important;
    }

    .btn-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .navigation--header {}
    .navigation--header--creativeai {
      padding-right: 1rem;
    }

    .dropdown-menu {
      background: #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
      border-radius: 5px 5px 10px 10px;
      padding: 0px;
    }

    .adset-all {
      margin-top: 5px;
      border-bottom: 1px solid #cfcece;
    }

    .navigation-adset-checkbox {
      label {
        &:before {
          padding: 8px;
          margin-right: 10px;
        }

        &:after {
          top: 6px;
          left: 7px;
          width: 5px;
          height: 10px;
        }
      }
    }

    .dropdown.hoverable .dropdown-menu a {
      font-family: Roboto;
      font-size: 13px !important;
      line-height: 24px;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 16rem;
    }

    .dropdown.hoverable .dropdown-menu a:hover {
      background: white !important;
    }

    .adset-apply {
      display: flex;
      justify-content: flex-end;
      padding: 8px 4px;
    }
  }

  .navigation_dropdown_container {
    padding-top: 10px !important;
  }


  .dropdown-row {
    margin-bottom: 7px;
    margin-right: 10px;
  }
}