.header-container {
  display: flex;
  justify-content: space-between;
  .date-range-picker {
    padding-top: 1rem;
    position: relative;
  }
  .performance-calendar {
    position: absolute;
    z-index: 1031;
    right: 10px;
    top: 70px;
    padding: 10px;
    .rdrStaticRange:nth-last-of-type(1) {
      display: none;
    }
    .rdrInputRange:nth-last-of-type(1) {
      display: none;
    }
  }
  .phase-container {
    display: flex;
    .btn-relative {
      position: relative;
      margin-top: 1rem;
      padding: 0;
    }
  }
}
