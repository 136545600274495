@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

:root {
  --defaultWhite: #fff;
  --defaultBlack: #000;
  --defaultBlue: #0869fb;
}

body {
  background: #f2f2f2 !important;
  color: #000;
  box-sizing: border-box !important;
  max-width: 100%;
  overflow-x: hidden;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar_margin {
  margin-right: 21px;
}

.app-container {
  // padding-left: 1rem !important;
  padding-left: 9px !important;
}

.campaigns {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding-bottom: 20px;
  max-height: 70vh;
  overflow-y: scroll;
}

.sort_style {
  margin-left: 5.2px;
  cursor: pointer;
}

.ai_model_confidence_style {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding-bottom: 30px;
}

.date-range-btn {
  .performance_button {
    color: #aaaaaa;
    font-size: 0.9rem;
    height: 35px;
    border-radius: 4px;

    &.active_button {
      background-color: #e6f0ff;
      border-radius: 4px;
      color: #0869fb;
    }
  }

  .performance_button:hover {
    border-radius: 4px;
    background-color: #edf3fe !important;
  }
}

.performance_button {
  margin-right: 19px;
  width: 150px;
  height: 27px;
  background: #feffff;
  border: none;
}

.btn-primary {
  background: #e9e9e9;
  border-radius: 8px;
  border-color: #e9e9e9;
  width: 198px;
  height: 40px;
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #000000;
}

.width-secondary {
  width: auto;
}

.dropdown-toggle::after {
  /* margin-left: 80px; */
  display: none !important;
  content: none !important;
}

/* .dropdown-menu.show {
    width: -webkit-fill-available;
  } */

.btn-primary:hover {
  color: #000000;
  background: #e9e9e9;
  border-color: #e9e9e9;
}

.group_header {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;

  align-items: center;
  color: #000000;
}

.date {
  margin-top: 7px;
  margin-left: 650px;
  width: 354px;
  height: 41px;
}

.optimizer_header_h4 {
  margin-left: 34px;
  margin-top: 22px;
  /* font-family: 'Roboto'; */
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
}

.sidebar {
  margin-left: 4px;
  margin-right: 5px;
  padding-top: 11px;
  min-height: 52px;
  padding-left: 27px;
}

.sidebar_text {
  margin-left: 8px;
  vertical-align: -webkit-baseline-middle;
  color: #000;
}

.sidebar_active {
  color: #0869fb;
}

.activeClass {
  border-radius: 8px;
  background-color: #edf3fe;
}

/* .activeClass svg {
    color: #0869FB !important;
  } */

.lable_style {
  /* font-family: Roboto; */
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  // line-height: 24px;
  color: #252525;
}

::-webkit-progress-value {
  background-color: #3ccdce !important;
}

::-moz-progress-bar {
  background-color: #3ccdce !important;
}

progress {
  -webkit-appearance: none;
}

.table_style thead th {
  vertical-align: top;
}

.link_style {
  text-decoration: none !important;
  color: #000000;
}

.table {
  width: 100% !important;
}

.table td,
.table th {
  border-top: none;
}

.table td {
  font-size: 0.7rem;
}

table {
  padding: 0 !important;
  margin: 0 !important;
  width: fit-content !important;
}

.table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
}

.table th {
  font-size: 0.6rem;
  font-weight: 700 !important;
  width: 12.5%;
  white-space: nowrap;

  p {
    margin: 0;
  }
}

.ReactChart {
  margin-top: 5px !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.rangeButtons {
  text-align: right;
}

.tableData {
  font-weight: normal;
  font-size: 9px;
}

.btn-primary:focus {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.btn-primary.dropdown-toggle {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.show > .btn-primary.dropdown-toggle {
  background-color: #e9e9e9;
  border-color: #e9e9e9;
}

.inner_image {
  position: absolute;
  margin-left: 34px;
}

.img_style {
  margin-left: 41px;
}

.username_style {
  margin-left: 14px;
}

.hr {
  margin-left: 11.5px;
  margin-bottom: 17.14px;
}

.tooltip-container {
  width: fit-content;
  display: inline;
  position: relative;

  .tooltip {
    display: none;
    width: 200px;
    // position: fixed;
  }

  &:hover .tooltip {
    display: block;
  }
}

.tooltip-header {
  font-weight: bold;
  color: #1c1e21;
  text-align: left;
  margin-bottom: 7.5px;
}

// tooltip css//
.custom-tooltip-layout {
  position: absolute;
  background-color: #ffffff !important;
  color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  opacity: 1 !important;
  padding: 8px;

  .tootltip-wrapper {
    width: fit-content;
    text-align: left;
    color: #a9a9aa;
    font-size: 0.7rem !important;
    background-color: #ffffff !important;
  }

  .tooltip-arrow {
    display: none !important;
  }

  .tooltip-inner {
    background-color: #fff !important;
  }
}

.tooltip .arrow {
  display: none !important;
}

.phase_content {
  font-size: 10px;
  color: #a9a9aa;
}

.phase_color {
  width: 45px;
  height: 8px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 0px;
  background-color: #0869fb;
}

.phase_color_active {
  background-color: #63be09;
}

.phase_border0 {
  border-radius: 0px;
}

.phase_border1 {
  border-radius: 66px 0px 0px 66px;
}

.phase_border2 {
  border-radius: 0px 66px 66px 0px;
}

.react-resizable {
  width: 100% !important;
}

.nav-link.active {
  background-color: #edf3fe !important;
  color: #0869fb !important;

  svg {
    fill: none !important;
  }

  .svg-content-item {
    path {
      fill: #fff;
      stroke: #0869fb !important;
    }

    circle {
      fill: none;
      stroke: #0869fb !important;
    }

    rect {
      fill: none;
      stroke: #0869fb !important;
    }

    path.path-fill {
      fill: #0869fb;
    }
  }
}

.nav-link {
  color: #333 !important;
  font-size: 0.82rem;
  margin-bottom: 5px;
  padding-top: 12px;
  padding-bottom: 12px;

  svg {
    fill: none !important;
    margin-right: 15px;
  }

  &:hover {
    background-color: #edf3fe !important;
  }

  .svg-content-item {
    path {
      fill: #fff;
      stroke: #333 !important;
    }

    path.path-fill {
      fill: #333;
    }
  }
}

.profile-image {
  border: "solid 1px #DDDFE2";
}

.sidebar-phase-container {
  width: 21%;
  background: white;
}

.left-container {
  width: 22.5% !important;
}

.right-container {
  width: 77.5% !important;
}

.journey {
  width: 100%;

  .progress {
    width: 80%;
    display: flex;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    height: fit-content;
  }

  .progress-chip {
    width: 20%;
    height: 10px;
    background-color: #e9ebee;
    border: 1px solid #d9d9d9;
  }

  .active-phase {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.dropdown {
  border-radius: 8px !important;

  > button {
    background: #e9e9e9 !important;
    border-radius: 8px !important;
    color: #000 !important;
    text-align: left !important;
    font-size: 0.8rem !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    margin-right: 0 !important;

    &:active {
      border: none !important;
      outline: none !important;
    }

    &:hover {
      background: #ccc !important;
    }
  }

  span {
    font-size: 0.6rem;
    font-weight: 400 !important;
  }

  .text-dark {
    font-size: 0.8rem;
  }

  a {
    background: #fff;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      width: 80%;
    }

    .dropdown_arrow {
      width: fit-content;
    }
  }
}

.dropdown.hoverable {
  &:hover {
    background: #ccc !important;
    border: none !important;
  }

  .dropdown-menu {
    overflow-y: scroll;
    overflow-x: unset;
    max-height: 200px;
    top: 100% !important;
    transform: none !important;
    width: 100%;

    a {
      background: #fff !important;
      font-size: 0.9rem !important;

      &:hover {
        background: #e9e9e9;
      }
    }
  }
}

.table-headline {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
}

.table-desc {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
}

.publish-cluster-tr {
  color: #7a7a7a;
}

.text {
  &-xs {
    font-size: 0.6rem !important;
  }

  &-sm {
    font-size: 0.7rem !important;
  }

  &-md {
    font-size: 0.8rem !important;
  }

  &-lg {
    font-size: 0.9rem !important;
  }
}

.btn {
  border: 0;
  padding: 10px 18px !important;
  margin: 0 10px 0 0;
  border-radius: 10px;
  font-size: 0.8rem !important;
  border: none;
  outline: none;
  box-shadow: none !important;

  &.primary {
    background: #0869fb;
    color: #fff;
    height: auto;

    &:hover {
      background: #2854ac;
      color: #fff;
    }

    &:active {
      background: #183c84;
    }
  }

  &.secondary {
    background: #f2f2f2;
    color: #242424;

    &:hover {
      background: #e8e4e4;
    }

    &:active {
      background: #e0dcdc;
    }
  }

  &.success {
    background: #80bc3c;
    color: #fff;

    &:hover {
      color: #fff;
      background: #608c2c;
    }

    &:active {
      background: #507424;
    }
  }

  &.light {
    background: #f2f2f2;
    color: #242424;
    font-weight: 600;
    padding: 12px 16px !important;
    font-size: 14px !important;
    line-height: 16px;
    border-radius: 10px;

    &:hover {
      background: #e8e4e4;
    }

    &:active {
      background: #e0dcdc;
    }
  }
}

.new-text {
  float: right;
  background: linear-gradient(90deg, #0571f0 5.36%, #7eb1ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.box-shadow {
  box-shadow: 0px 1px 12px rgb(0 0 0 / 10%);
}

.warning {
  font-size: 12px;
  line-height: 14px;
  background: #fff5f5;
}

.color-chip {
  width: 15px;
  height: 15px;
  border-radius: 4px;
}

.mw-9-rem {
  max-width: 9rem;
}

.z-index-10 {
  z-index: 10;
}

.borderless-table {
  border-bottom-width: 0px !important;
}

.form-control:focus {
  border-color: #dddddd !important;
  box-shadow: none !important;
}

.search-text {
  .search-icon {
    position: absolute;
    top: 27%;
    left: 1.5%;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
}

.publish-cluster-error {
  position: absolute;
  height: 90%;
  width: 100%;

  .publish-cluster-child-error {
    position: relative;
    top: 35%;
    -webkit-transform: translateX(-5%);
    -ms-transform: translateX(-5%);
    transform: translateX(-5%);

    .refresh-icon {
      cursor: pointer;
    }
  }
}

::-webkit-scrollbar {
  //**** Will search  alternate in future ***//
  height: 0;
  width: 0;
}

.initial-style {
  font-size: 22px !important;
  font-family: "Lato";
  font-weight: 700 !important;
  font-style: normal;
  display: flex;
  align-items: center;
  color: #000000;
  line-height: 26px;
}
