
.sidebar-svg-name{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
}
.sidebar-item-content {
    overflow: auto !important;
    overflow-x: hidden !important;
    height: 570px;
}

.sidebar-item-content::-webkit-scrollbar {
    height: 0;
    width: 0;
}

.up-arrow-icon {
    .sidebar-dropdown {
        transform: rotate(0deg);
    }
}

.down-arrow-icon{
    .sidebar-dropdown {
    transform: rotate(180deg); 
    }
}
