/* Modal Styling */

.modal {
  z-index:99999999999;
  .body-tagline {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 1.2rem;
  }
  .modal-title {
    box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
    .btn-close {
      padding: 0.56em 0.56em;
      &:hover {
        background-color: #f2f2f2;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal-body {
    height: 100% !important;
    overflow: scroll;
    padding: 0.8em 1.5rem;
  }
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
