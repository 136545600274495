.create-ad {
  .create-ad-selection--heading {
    padding: 24px 0px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    display: flex;
    align-items: center;
    position: relative;
    text-transform: capitalize;
    .create-ad--icon-container {
      svg {
        cursor: pointer;
      }
    }
    .create-ad--icon-container:hover .create-ad-heading--popup {
      display: block;
    }
    .create-ad-heading--popup {
      display: none;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      padding: 12px;
      background: white;
      position: absolute;
      top: 50px;
      right: 0px;
      width: 90%;
      color: #666666;
      color: #a9a9aa;
      font-size: 0.8rem;
      z-index:1
    }
  }
  .selection-container {
    margin-bottom: 24px;
  }
  .icon-container {
    width: 20px;
  }
  .create-ad--pin-icon{
      width: 11px;
  }
  .create-ad--delete-icon, .create-ad--pin-icon, .create-ad--lock-icon {
    opacity: 0;
    cursor: pointer;
  }
  .selection-container:hover {
    .create-ad--delete-icon, .create-ad--pin-icon, .create-ad--lock-icon {
      opacity: 1;
    }
  }
  .create-ad-selection--body{
    display:flex;
    flex-direction:column;
    .replace-section{
      &.inactive{
        opacity: .5;
        pointer-events: none;
      }
    .days{
      -moz-appearance: textfield;
      width:30px;
      background-color: #fff;
      border: 1px solid #8a8a8a;
      border-radius: 3px;
      box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    

    }

    .links--container{
      display:flex;
      flex-direction: row;
    }
    a.link{
      font-size:12px;
      &.inactive{
        color: #a7a7a7;
        pointer-events:none;
      }
    }
  }
  .create-ad-selection--footer {
    display: flex;
    justify-content: flex-end;
    .btn-generate-more {
      border-radius: 70px;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #45494f;
      padding: 15px 25px;
    }
  }
  .create-ad--footer {
    font-family: Roboto;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    margin-top: 24px;
    b {
      font-weight: 900;
      font-size: 14px;
      color: #333333;
    }
  }
  .selected-rows{
    .table{
      th,td{
        padding:19px;
      }
    }
  }
}

.handle-gsai-error{
  background-color: #F6F6F6;
  margin-bottom:25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #E0E0E0;

  h6{
    padding-top: 15px;
    color : black;
    opacity: 0.7;
    margin-bottom: 6px;
  }
  p{
   color : black;
   opacity: 0.7;
   text-align: center;
   font-size: 15px;
   margin: 6px 10px 20px 10px;
  }

  button{
    background-color:transparent;
    padding: 0px;
    margin:0px 0px 20px 0px;

  }
}
