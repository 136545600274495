.all-platform-graph-container{
    padding: 0rem 1rem 0rem 0.7rem;
    display: flex;
    .pie-chart-container{
        width: 22rem !important;
    }
    .line-chart-container{
        width: 52rem !important;
        height: 37vh !important;
        margin-right: 1%;
        padding-top: 1rem;
        canvas {
            height: inherit !important;
        }
    }
}

.nodata{
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: -30px;
    justify-content: center;
    align-items: center;
}