.tableWrap {
  height: calc(100% - 58px);
  overflow: auto;
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    padding: 16px;
    padding-left: 15px;
    text-align: left;
  }
}
.pyxis-table-container::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

.pyxis-table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
}

.pyxis-table-container::-webkit-scrollbar-thumb {
  background-color: rgb(184, 182, 182);
  outline: 1px solid rgb(142, 169, 197);
  border-radius: 0.3rem;
}
