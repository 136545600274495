@import "src/scss/variables.scss";

.drawer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: calc(100vw - 10vw);
  height: 100vh;
  transform: translate(300px, 0);
  will-change: transform, visibility;
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index:1031;
}

.drawer[aria-hidden="false"] {
  transform: translate(0, 0);
  transition: transform 0.3s linear;
}

.drawer[aria-hidden="true"] {
  transition: visibility 0s linear 0.3s, transform 0.3s linear;
}

.drawer-container{
  display:flex;
}

.drawer-container--close-container {
  width: $drawer-close-container;
  height: 100vh;
  background-color:#616162;
}

.drawer-container--close{
  padding:40px 12px;
  cursor:pointer;
}

.drawer-container--body{
  // width: calc(100% - $drawer-close-container);
  width: calc(100% - 60px);
  padding: 24px 24px 0px;
  position: relative;
  display:flex;
  flex-direction:column;
  height:100vh;
}