.profitability {
    background: #ffffff;
    box-shadow: 0px 1px 12px rgb(0 0 0 / 10%);
    border-radius: 10px;
    margin: 24px;
    padding: 0 0 30px;

    hr {
      margin: 0 24px;
    }

    .campaign_sub_heading {
      font-size: 12px;
      padding-left: 24px;
      color: #666666;
    }

    .metric_sub_heading {
      font-size: 12px;
      padding-left: 24px;
      color: #666666;
      margin: 0;
    }

    .slider-rc {
      display: flex;
      margin: 0 24px;

      p {
        margin: 0;
        font-size: 11px;
        align-self: end;
      }

      .slider-container {
        width: fit-content;
        .slider-percent-label {
          display: flex;
          position: relative;
          padding-top: 15px;

          p {
            position: absolute;
          }
        }
      }
    }

    .profitability_roi_metric {
      padding: 10px 24px;

      .info-urgent {
        color: #f58a8a;
        font-size: 12px;
      }

      .btn-add-more {
        display: flex;
        justify-content: space-around;
        padding: 0;
        border-radius: 10px;
        font-size: 12px;
        width: 150px;
        height: 35px;

        .btn-plus {
          font-size: 24px;
        }
      }
    }
  }
  .campaign-footer{
    
    margin:10px;
    margin-left: 85%;
    .btn-apply{
      background-color: rgb(14, 136, 236);
      border: none;
      border-radius: 4px;
      color:white;
      font-size: small;
      width:60px;
      height: 30px;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
  }
  .result_metric_key{
    width:80px;
    height:30px;
    background-color: "black";
    border:1px solid #ced4da;
    border-radius: 4px;
  }
  .view-ads{
    background-color: rgb(14, 136, 236);
    color: white;
  }
  
  .currency-symbol{
    width:40px;
    height:35px;
    background-color: white;
    padding: 0px;
    text-align: center;
  }
  .currency-input{
    display: flex;
  }